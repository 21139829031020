import React from "react";
var __jsx = React.createElement;
import Head from 'next/head';

var App = function App(_ref) {
  var Component = _ref.Component,
      pageProps = _ref.pageProps;
  return __jsx(React.Fragment, null, __jsx(Head, null, __jsx("title", null, "MS\uD300\uC988 \uD0ED with NextJS \uB370\uBAA8"), __jsx("meta", {
    name: "viewport",
    content: "minimum-scale=1, initial-scale=1, width=device-width"
  })), __jsx(Component, pageProps));
};

export default App;